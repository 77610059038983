
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n/index";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import SampleCode from "@/components/manual/SampleCode.vue";
import ApiHeader from "@/components/manual/ApiHeader.vue";
import CallbackResponse from "@/components/manual/CallbackResponse.vue";
import LiveCallbackTester from "@/components/manual/LiveCallbackTester.vue";

export default defineComponent({
  name: "live-rollback",
  components: {
    ApiHeader,
    LiveCallbackTester,
    CallbackResponse,
    SampleCode,
  },
  setup() {
    // vue variables
    const { t } = useI18n();
    // page variables
    const successResponse = {
      balance: 1000,
      member_id: "9fd4acc3-fa2c-4fc4-a530-fa517e8cdd6d",
      txn_id: "88944aaf-0a02-4776-a151-326538051926",
    };
    const errorResponse = {
      message: "INSUFFICIENT_FUNDS",
    };
    const errorComment = `
    /* INVALID_DEBIT = 베팅 txn_id 찾을 수 없는 경우
    INVALID_MEMBER = 회원 아이디 찾을 수 없는 경우
    DUPLICATE_ROLLBACK = 똑같은 베팅 txn_id 경우
    ACCESS_DENIED = 헤더 맞지 않은 경우
    ERROR_OCCURED = 서버 오류 발생 경우 */\r`;

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs(t("ApiManual.Rollback"), [
        t("ApiManual.RealtimeCasino"),
      ]);
    });

    const phpCode = `<?php

function rollback() {

  $snowSec = getallheaders()['snowsec'];
  $agentCode = getallheaders()['agentcode'];
  $agentId = getallheaders()['agentid'];

  $data = json_decode(file_get_contents('php://input'), true);

  if($agentCode === AGENTCODE && $agentId === AGENTID && $snowSec === SNOWSEC) {

    try {

      $member = $data['member_id'];  // 디비에서 SNOW멤버 검색
      $betting = $data['txn_id']; // 디비에서 베팅 Transaction ID 검색
      $amount = intval($data['amount']); // 환급금액

      $balance = 0; // 회원 보유금액
      $newBalance = $newBalance + intval($amount);

      if(!$member) { // 회원 찾을 수 없음
        header("HTTP/1.1 404 Not Found");
        $response = ['message' => 'INVALID_MEMBER'];
        return print_r(json_encode($response));
      }

      if(!$betting) { // 베팅 txn_id 찾을 수 없음
        header("HTTP/1.1 400 Bad Request");
        $response = ['message' => 'INVALID_DEBIT'];
        return print_r(json_encode($response));
      }

      // 디비에서 베팅 업데이트 

      $response = array(
        'member_id' =>$data['member_id'],
        'txn_id' = $data['txn_id'],
        'balance' => $newBalance
      );
      print_r(json_encode($response));

    } catch(Exception $e) {
      header("HTTP/1.1 500 Internal Server Error");
      $response = ['message' => 'ERROR_OCCURED'];
      print_r(json_encode($response));
    }
  } else {
    header("HTTP/1.1 403 Forbidden");
    $response = ['message' => 'ACCESS_DENIED'];
    print_r(json_encode($response));
  }
}
?>
`;
    const aspCode = `<!--#include virtual="/jsonObject.class.asp" -->

<%

response.ContentType="application/json"

'Read JSON Request
bytecount = Request.TotalBytes
bytes = Request.BinaryRead(bytecount)
		
If bytecount = 0 Then

'INVALID REQUEST

End If

SNOWSEC = Request.ServerVariables("HTTP_SNOWSEC")
AGENTCODE = Request.ServerVariables("HTTP_AGENTCODE")
AGENTID = Request.ServerVariables("HTTP_AGENTID")

If SNOWSEC = SNOWSEC And AGENTCODE = AGENTCODE And AGENTID = AGENTID Then

	Set stream = Server.CreateObject("ADODB.Stream")
		stream.Type = 1 'adTypeBinary              
		stream.Open()                                   
		stream.Write(bytes)
		stream.Position = 0                             
		stream.Type = 2 'adTypeText                
		stream.Charset = "utf-8"
		s = stream.ReadText() 'here is your json as a string                
		stream.Close()
	Set stream = nothing

	req_object = s

	dim jsonObj, outputObj
		
	set jsonObj = new JSONobject
	set outputObj = jsonObj.parse(req_object)


	txn_id = outputObj("txn_id")
	member_id = outputObj("member_id")
	amount = outputObj("amount")
	reason = outputObj("reason")

	If member_id <> MEMBER Then

		Response.Status = "404 Not Found"
		Response.write "{"
		Response.Write chr(34)&"message"&chr(34)&":"&chr(34)&"INVALID_MEMBER"&chr(34)
		response.write "}"

	End If

	If txn_id = "" Then

		Response.Status = "400 Bad Request"
		Response.write "{"
		Response.Write chr(34)&"message"&chr(34)&":"&chr(34)&"INVALID_DEBIT"&chr(34)
		response.write "}"

	End If

	balance = 0
	newbalance = balance + winamount  'NEW USER BALANCE

	Response.write "{"
	Response.Write chr(34)&"member_id"&chr(34)&":"&chr(34)&member_id&chr(34)
	Response.Write ","&chr(34)&"txn_id"&chr(34)&":"&chr(34)&txn_id&chr(34)
	Response.Write ","&chr(34)&"balance"&chr(34)&":"& balance
	response.write "}"

Else

	Response.Status = "403 Forbidden"
	Response.write "{"
	Response.Write chr(34)&"message"&chr(34)&":"&chr(34)&"ACCESS_DENIED"&chr(34)
	response.write "}"

End If

%>`;
    const aspNetCode = ``;

    return {
      successResponse,
      errorResponse,
      errorComment,
      phpCode,
      aspCode,
      aspNetCode,
    };
  },
});
